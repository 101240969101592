import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import moment from "moment";

import SEO from "../components/seo";
import Layout from "../components/Layout";
import {Flex} from "../style/Grid";
import usePageView from "../utils/usePageView";
import styled from 'styled-components';
import { themeGet } from "@styled-system/theme-get";
import {theme} from "../style/theme";

const StyledIframe = styled.iframe`
  max-width: 1217px;
  @media screen and (max-width: ${themeGet("breakpoints.sm")}) {
    max-width: 100%;
    height: 600px;
  }
  border: 1px solid ${theme.colors.EYGrey02.default};
`;


const ChatbotPage = ({ data, location }) => {
  usePageView(location.pathname, "Chatbot", "");

  const { frontmatter: content } = data.mdx;

  return (
    <Layout location={location} title="event" menuInverse hideFooterMobile>
      <SEO title={content.metaTitle} description={content.metaDescription} />
      <Flex
        flexDirection={["column", null, "row", null, null]}
        justifyContent="center"
        mt={["64px", null, null, "120px", null]}
        mb={["30px", null, null, "120px", null]}
      >
        {content?.iframeSrc && (
          <StyledIframe
            allow="clipboard-write; microphone"
            width="100%"
            height="450"
            src={`${content.iframeSrc}`}
            allowFullScreen
          />
        )}
      </Flex>
    </Layout>
  );
};

export default ChatbotPage;

export const pageQuery = graphql`
  query chatbotQuery {
    mdx(frontmatter: { templateKey: { eq: "chatbot-page" } }) {
      frontmatter {
        title
        metaTitle
        metaDescription
        iframeSrc
        headerImage {
          image {
            childImageSharp {
              gatsbyImageData(width: 1920, quality: 90)
            }
          }
          imageAlt
        }
      }
    }
  }
`;
